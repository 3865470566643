import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Fab, Grid, Pagination, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useProgress } from "../../../../hooks/useProgress";
import { Floor, Guild, User } from "../../../../interface";
import { ScrollTop } from "../../common/button/ScrollTop";
import { TweetButton } from "../../common/button/TweetButton";

import { MonthBox } from "./contents/MonthBox";

const FloorSelect = ({
  revealedFloorNumber,
  guildData,
  userData,
  allFloorData,
}: {
  revealedFloorNumber: number;
  guildData: Guild | null;
  userData: User | null;
  allFloorData: Map<string, Floor>;
}) => {
  const navigate = useNavigate();

  const {
    isGuildSolved109Problem,
    isFloorsSolved,
    isFloorsSolvedByGuild,
    isGuildSolved108Problem,
  } = useProgress(userData, guildData);

  const handleDisplayFloor = (floorIndex: number) => {
    revealedFloorNumber > floorIndex - 1 &&
      navigate("/floor/" + floorIndex.toString());
  };

  const handlePagination = React.useCallback((e: any) => {
    const month = e.target.innerText;
    const offset = {
      // @ts-ignore
      top: document.querySelector(`#month-${month}`)?.offsetTop ?? 0,
      // @ts-ignore
      left: document.querySelector(`#month-${month}`)?.offsetLeft ?? 0,
      behavior: "smooth",
    };
    // @ts-ignore
    window.scrollTo(offset);
  }, []);

  return (
    <>
      <div>
        {isGuildSolved109Problem ? (
          <div>
            <Typography variant="h4" component="h1" sx={{ m: 2 }}>
              あなたは2023年への第1歩を踏み出した！
            </Typography>
            <TweetButton
              tweetContent={{
                text: `あなたは2023年への第1歩を踏み出した！
#年越し謎2022
https://goodbye2022.enigmahouse.jp`,
              }}
            />
          </div>
        ) : isGuildSolved108Problem ? (
          <div>
            <Typography variant="h4" component="h1" sx={{ m: 2 }}>
              おめでとうございます！あなたは108個すべての煩悩を晴らしました！よいお年を！
            </Typography>
            <TweetButton
              tweetContent={{
                text: `おめでとうございます！あなたは108個すべての煩悩を晴らしました！よいお年を！
#年越し謎2022
https://goodbye2022.enigmahouse.jp`,
              }}
            />
          </div>
        ) : userData !== null ? (
          <div>
            <Typography variant="h5" component="h1" sx={{ m: 2 }}>
              あなたは108問中 {userData.solvedCount.toString()}問解き明かした！
            </Typography>
            {guildData !== null && (
              <Typography variant="h5" component="h1" sx={{ m: 2 }}>
                あなたのギルドは108問中 {guildData.solvedCount.toString()}
                問解き明かした！
              </Typography>
            )}
          </div>
        ) : (
          <div>
            <Typography variant="h5" component="h1" sx={{ m: 2 }}>
              ログインすることで解答状況を記録できます。
            </Typography>
          </div>
        )}

        <Grid component="p" sx={{ my: 1 }}>
          {guildData !== null && (
            <TweetButton
              tweetContent={{
                text: `ギルドで合計${guildData.solvedCount}問を解き明かした！
#年越し謎2022
https://goodbye2022.enigmahouse.jp`,
              }}
            >
              ギルド進捗をツイート
            </TweetButton>
          )}
          {userData !== null && (
            <TweetButton
              tweetContent={{
                text: `個人で合計${userData.solvedCount}問を解き明かした！
#年越し謎2022
https://goodbye2022.enigmahouse.jp`,
              }}
            >
              個人進捗をツイート
            </TweetButton>
          )}
        </Grid>

        <Pagination
          count={Math.ceil(revealedFloorNumber / 9)}
          boundaryCount={Math.ceil(revealedFloorNumber / 9)}
          hidePrevButton
          hideNextButton
          size="large"
          onClick={handlePagination}
          id="back-to-top-anchor"
        />
        <Grid
          id="month-box"
          container
          sx={{
            width: "100%",
            ml: 1,
          }}
        >
          {[...new Array(Math.ceil(revealedFloorNumber / 9))].map((_, i) => (
            <Grid item xs={12} key={i}>
              <MonthBox
                monthIndex={i}
                revealedFloorNumber={revealedFloorNumber}
                allFloorData={allFloorData}
                isFloorsSolved={isFloorsSolved}
                isFloorsSolvedByGuild={isFloorsSolvedByGuild}
                handleDisplayFloor={handleDisplayFloor}
                key={i}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <ScrollTop>
        <Fab color="secondary" size="large" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default FloorSelect;
