import { Floor, Guild, User } from "../../../interface";
import firestore from "firebase/firestore";

export const userConverter = {
  toFirestore(post: User): firestore.DocumentData {
    return post;
  },
  fromFirestore(
    snapshot: firestore.QueryDocumentSnapshot,
    options: firestore.SnapshotOptions
  ): User {
    const doc = snapshot;
    const newUserData: User = {
      userName: doc.data()?.userName,
      guildId: doc.data()?.guildId,
      joinedGuildId: doc.data()?.joinedGuildId,
      solvedCount: doc.data()?.solvedCount,
      floors: doc.data()?.floors,
      is109FloorRevealed: doc.data()?.is109FloorRevealed,
      picture: doc.data()?.picture,
    };
    return newUserData;
  },
};

export const guildConverter = {
  toFirestore(post: Guild): firestore.DocumentData {
    return post;
  },
  fromFirestore(
    snapshot: firestore.QueryDocumentSnapshot,
    options: firestore.SnapshotOptions
  ): Guild {
    const doc = snapshot;
    const newGuildData: Guild = {
      name: doc.data()?.name,
      solvedCount: doc.data()?.solvedCount,
      floors: doc.data()?.floors,
      memberIds: doc.data()?.memberIds,
      active: doc.data()?.active,
      guildOwner: doc.data()?.guildOwner,
      latestAnswerAt: doc.data()?.latestAnswerAt,
    };
    return newGuildData;
  },
};

export const floorConverter = {
  toFirestore(post: Floor): firestore.DocumentData {
    return post;
  },
  fromFirestore(
    snapshot: firestore.QueryDocumentSnapshot,
    options: firestore.SnapshotOptions
  ): Floor {
    const doc = snapshot;
    const newFloorData: Floor = {
      problemImage: doc.data()?.problemImage,
      solutionImage: doc.data()?.solutionImage,
      author: doc.data()?.author,
      title: doc.data()?.title,
      authorTwitterAccount: doc.data()?.authorTwitterAccount,
      answerArray: doc.data()?.answerArray,
      comment: doc.data()?.comment,
      specialArray: doc.data()?.specialArray,
      specialText: doc.data()?.specialText,
    };
    return newFloorData;
  },
};
