import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import img1 from "../../../img/about1.png";
import img2 from "../../../img/about2.png";
import img3 from "../../../img/about3.png";
import { HomeButton } from "../common/button/HomeButton";
import { PageTitle } from "../common/typography/PageTitle";

export const Rule = () => {
  const navigate = useNavigate();
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const onResize = useCallback(() => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  }, [setSize]);

  useEffect(() => {
    window.addEventListener("resize", onResize, true);
    return () => {
      window.removeEventListener("resize", onResize, true);
    };
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <img src={img1} style={{ width: "100%" }}></img>
        <img src={img2} style={{ width: "100%" }}></img>
        <img src={img3} style={{ width: "100%" }}></img>
        <ul>
          <li>
            トラブルや疑問点などがありましたら
            <a
              href="https://twitter.com/toshikoshinazo"
              style={{ color: "#fff" }}
            >
              年越し謎公式
            </a>
            にお問い合わせください。
          </li>
        </ul>
      </Grid>
      <HomeButton />
    </>
  );
};
