import { Card, CardContent, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { callUpdateUser } from "../../../../../api/cloudFunctions/user/callUpdateUser";
import { uploadImage } from "../../../../../api/storage/uploadImage";
import { makeImageName } from "../../../../../lib/makeImageName";
export const ChangeUserPicture = ({
  uid,
  setIsButtonDisabled,
  isGuestMode,
}: {
  uid: string;
  setIsButtonDisabled: (arg: boolean) => void;
  isGuestMode: boolean;
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [blobUrl, setBlobUrl] = useState<null | string>(null);
  const [message, setMessage] = useState("");
  useEffect(() => {
    setMessage("");
    if (file !== null) {
      setBlobUrl(window.URL.createObjectURL(file));
    } else {
      setBlobUrl(null);
    }
  }, [file]);
  const handleButton = async () => {
    setIsButtonDisabled(true);
    if (file === null) {
      return;
    }
    const imagePath = "profile/" + makeImageName(file);
    const imageUrl = await uploadImage(file, imagePath);
    if (imageUrl === null) {
      setMessage(
        "画像アップロードに失敗しました。時間をおいて再度お試しください。"
      );
      return;
    }
    const result = await callUpdateUser({ picture: imageUrl, uid });
    setMessage(result ? "画像を変更しました。" : "画像の変更に失敗しました。");
    setIsButtonDisabled(false);
  };

  return (
    <>
      {" "}
      {!isGuestMode && (
        <Card sx={{ my: 2 }}>
          <CardContent>
            <ImageForm setFile={setFile} title="プロフィール画像の変更" />
            {blobUrl !== null && (
              <img
                src={blobUrl}
                alt="プレビュー"
                style={{ width: 40, height: 40 }}
              />
            )}
            <Button variant="contained" onClick={handleButton}>
              変更する
            </Button>
            <p>{message}</p>
          </CardContent>
        </Card>
      )}
    </>
  );
};

type ImageFormProps = {
  title?: string;
  setFile: (prop: File | null) => void;
};
export const ImageForm = ({ setFile, title }: ImageFormProps) => {
  const fileSizeLimit = 1024 * 1024;

  const [message, setMessage] = useState("");

  const handleInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    if (file === undefined || file === null) {
      setMessage("");
      return;
    }
    if (file.size <= fileSizeLimit) {
      setMessage("");
      setFile(e.target.files?.item(0) ?? null);
    } else {
      setMessage("1MB以上の画像は送信できません。");
      setFile(null);
    }
  };

  return (
    <>
      <Typography variant="h4" component="h1">
        {title ?? "画像アップロード"}
      </Typography>
      <input type="file" onChange={handleInput} accept="image/png,image/jpeg" />
      <p>{message}</p>
    </>
  );
};
