import {
  Dialog,
  Alert,
  DialogTitle,
  AlertTitle,
  DialogContentText,
  DialogActions,
  Button,
  DialogContent,
} from "@mui/material";
import { FC, PropsWithChildren } from "react";

export type CorrectDialogProps = {
  open: boolean;
  closeHandler: () => void;
};
export const CorrectDialog = (props: PropsWithChildren<CorrectDialogProps>) => (
  <Dialog
    open={props.open}
    onClose={props.closeHandler}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    color="success"
  >
    <Alert severity="success">
      <DialogTitle id="alert-dialog-title">
        <AlertTitle
          sx={{
            fontSize: "34px",
            fontWeight: "bold",
          }}
        >
          正解です！
        </AlertTitle>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeHandler}>閉じる</Button>
      </DialogActions>
    </Alert>
  </Dialog>
);
