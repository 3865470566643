import { httpsCallable } from "firebase/functions";
import { functions } from "../../..";

export const callUpdateGuildName = async (
  uid: string,
  guildNameInputValue: string,
  setMessage: (message: string) => void
) => {
  if (guildNameInputValue !== "") {
    const updateGuildNameFunction = httpsCallable(functions, "updateGuildName");
    try {
      if (
        await updateGuildNameFunction({
          newGuildName: guildNameInputValue,
          uid: uid,
        })
      ) {
        setMessage("saved");
      } else {
        setMessage("failed saving");
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    setMessage("ギルド名を入力してください!");
  }
};
