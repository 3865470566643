import {
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { getUserName } from "../../../../api/firestore/dataLoader";
import { Guild, User } from "../../../../interface";
import { PageTitle } from "../../common/typography/PageTitle";
import { SectionTitle } from "../../common/typography/SectionTitle";
import { ChangeGuildName } from "./contents/ChangeGuildName";
import { ChangeGuildOwner } from "./contents/ChangeGuildOwner";
import { LeaveGuild } from "./contents/LeaveGuild";
import { MakeGuildInviteCode } from "./contents/MakeGuildInviteCode";
import { ReceiveGuildInviteCode } from "./contents/ReceiveGuildInviteCode";
import { ShowGuildMemberList } from "./contents/ShowGuildMemberList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

type GuildConfigProps = {
  isGuestMode: boolean;
  isButtonDisabled: boolean;
  setIsButtonDisabled: (prop: boolean) => void;
  guildData: Guild | null;
  userData: User | null;
  uid: string;
};

export const GuildConfig = ({
  isButtonDisabled,
  setIsButtonDisabled,
  isGuestMode,
  guildData,
  userData,
  uid,
}: GuildConfigProps) => {
  const [memberNameList, setMemberNameList] = useState([
    { memberName: "", memberId: "" },
  ]);
  useEffect(() => {
    if (guildData === null) {
      setMemberNameList([{ memberName: "", memberId: "" }]);
      return;
    }
    const f = async () => {
      let newMemberNameList = await Promise.all(
        guildData.memberIds.map(async (memberId) => {
          if (!memberId) {
            return { memberName: "", memberId: "" };
          }
          const oneMemberName = await getUserName(memberId);
          return oneMemberName;
        })
      );
      newMemberNameList = newMemberNameList.filter(
        (value) => value.memberName !== ""
      );
      setMemberNameList(newMemberNameList);
    };
    f();
  }, [guildData?.memberIds, userData?.joinedGuildId, guildData]);

  if (userData === null) {
    return <p>各種設定はログイン後利用できます。</p>;
  }

  return !isGuestMode ? (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isButtonDisabled}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={12}>
        <PageTitle>ギルド設定</PageTitle>
      </Grid>
      <Grid item xs={12}>
        <ShowGuildMemberList
          guildData={guildData}
          userData={userData}
          uid={uid}
          setIsButtonDisabled={setIsButtonDisabled}
          memberNameList={memberNameList}
        />
        {guildData === null ? (
          <>
            <Card>
              <CardContent>
                <SectionTitle>ギルドを新規作成</SectionTitle>
                <ChangeGuildName
                  setIsButtonDisabled={setIsButtonDisabled}
                  isNewGuild={true}
                  uid={uid}
                />
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <SectionTitle>ギルドに参加</SectionTitle>
                <ReceiveGuildInviteCode
                  setIsButtonDisabled={setIsButtonDisabled}
                  guildId={userData.joinedGuildId}
                  isSolo={true}
                  uid={uid}
                />
              </CardContent>
            </Card>
          </>
        ) : userData.guildId === userData.joinedGuildId ? (
          <>
            {guildData.memberIds.length > 1 && (
              <Card>
                <CardContent>
                  <SectionTitle>ギルドオーナーの変更</SectionTitle>
                  <ChangeGuildOwner
                    guildData={guildData}
                    userData={userData}
                    uid={uid}
                    setIsButtonDisabled={setIsButtonDisabled}
                    memberNameList={memberNameList}
                  />
                </CardContent>
              </Card>
            )}
            <Card>
              <CardContent>
                <SectionTitle>ギルド名を変更する</SectionTitle>
                <ChangeGuildName
                  setIsButtonDisabled={setIsButtonDisabled}
                  isNewGuild={false}
                  uid={uid}
                />
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <SectionTitle>ギルド招待コードを発行する</SectionTitle>
                <MakeGuildInviteCode
                  guildId={userData.joinedGuildId}
                  setIsButtonDisabled={setIsButtonDisabled}
                  guildName={guildData.name}
                  uid={uid}
                />
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <SectionTitle>ギルドを併合する</SectionTitle>
                <ReceiveGuildInviteCode
                  setIsButtonDisabled={setIsButtonDisabled}
                  guildId={userData.joinedGuildId}
                  isSolo={guildData.memberIds.length === 1}
                  uid={uid}
                />
              </CardContent>
            </Card>
          </>
        ) : (
          <Card>
            <CardContent>
              <LeaveGuild
                setIsButtonDisabled={setIsButtonDisabled}
                userData={userData}
                uid={uid}
              />
            </CardContent>
          </Card>
        )}
      </Grid>
    </>
  ) : (
    <>
      <p>Twitterにログインしていない場合この機能は利用できません。</p>
    </>
  );
};
