import { useEffect, useRef, useState } from "react";
import { loadGuildData } from "../api/firestore/dataLoader";
import { Guild } from "../interface";

export const useGuildData = (guildId: string | undefined) => {
  const [guildData, setGuildData] = useState<Guild | null>(null);
  const stopGuildDataSnapshotRef = useRef(() => {});
  useEffect(() => {
    if (guildId === undefined) {
      return;
    }
    stopGuildDataSnapshotRef.current();
    stopGuildDataSnapshotRef.current = loadGuildData(guildId, setGuildData);
  }, [guildId]);

  return { guildData };
};
