import {
  Dialog,
  Alert,
  DialogTitle,
  AlertTitle,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { FC, PropsWithChildren } from "react";

export type WrongDialogProps = {
  open: boolean;
  closeHandler: () => void;
  text?: string;
};
export const WrongDialog = (props: PropsWithChildren<WrongDialogProps>) => (
  <Dialog
    open={props.open}
    onClose={props.closeHandler}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    color="error"
  >
    <Alert severity="error">
      <DialogTitle id="alert-dialog-title">
        <AlertTitle
          sx={{
            fontSize: "34px",
            fontWeight: "bold",
          }}
        >
          {props.text ?? "不正解です！"}
        </AlertTitle>
      </DialogTitle>
      <DialogContentText id="alert-dialog-description">
        {props.children}
      </DialogContentText>
      <DialogActions>
        <Button onClick={props.closeHandler}>閉じる</Button>
      </DialogActions>
    </Alert>
  </Dialog>
);
