import {
  Box,
  Container,
  CssBaseline,
  GlobalStyles,
  ThemeOptions,
  ThemeProvider,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import "firebase/auth";
import { getAuth, signOut } from "firebase/auth";
import { ReactNode, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Route, Routes, useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { checkSessionCookie } from "./api/cloudFunctions/sso/checkSessionCookie";
import { loginWithCustomToken } from "./api/cloudFunctions/sso/loginWithCustomToken";
import { callCreateUser } from "./api/cloudFunctions/user/callCreateUser";
import { loadAllFloorData } from "./api/firestore/dataLoader";
import { CommonHeader } from "./components/views/common/CommonHeader";
import { ConfigTop } from "./components/views/pages/config/Config";
import { GuildConfig } from "./components/views/pages/config/GuildConfig";
import { UserConfig } from "./components/views/pages/config/UserConfig";
import { FirstLogin } from "./components/views/pages/firstLogin/FirstLogin";
import { SuccessCreateNewAccount } from "./components/views/pages/firstLogin/firstLogin/Success";
import { FloorPage } from "./components/views/pages/floor/Floor";
import FloorSelect from "./components/views/pages/floorSelect/FloorSelect";
import { NotFound } from "./components/views/static/NotFound";
import { NowLoading } from "./components/views/static/NowLoading";
import { Rule } from "./components/views/static/Rule";
import "./css/App.css";
import { useGuildData } from "./hooks/useGuildData";
import { useIsGuestMode } from "./hooks/useIsGuestMode";
import { useRevealedFloorNumber } from "./hooks/useRevealedFloorNumber";
import { useUserData } from "./hooks/useUserData";
import headerVisual from "./img/header.jpg";
import { Floor } from "./interface";
import { logger } from "./lib/logger";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#fbc21d",
      dark: "#90a4ae",
    },
    secondary: {
      main: "#fa4705",
    },
    success: {
      main: "#00c853",
      contrastText: "#ffffff",
    },
    info: {
      main: "#2196f3",
    },
    background: {
      paper: "#302835",
      default: "#181053",
    },
    action: {
      disabledBackground: "#EADAAF",
      disabled: "#ffffff",
    },
  },
};

const theme = createTheme(themeOptions);

export const floorsCount = 108;

const App = () => {
  const location = useLocation();

  const [uid, setUid] = useState<string | null>(null);
  const isGuestMode = useIsGuestMode(uid);
  const [allFloorData, setAllFloorData] = useState(new Map<string, Floor>());

  const { userData, isUserDataLoaded, isFloorsSolved } = useUserData(uid);
  const { guildData } = useGuildData(userData?.joinedGuildId);

  // Enigmahouseでのログイン後にユーザーデータを設定する処理
  const auth = getAuth();
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    //1. 年越し謎側でログイン状況の確認
    if (loading) {
      return;
    }
    //2. sessionCookieで認証
    //parentUidがバグってる
    checkSessionCookie().then(({ uid, customToken }) => {
      if (user !== null) {
        if (uid !== null && customToken !== null) {
          if (uid === user.uid) {
            //2-1. 矛盾しない
            logger("case2-1", user.uid);
            setUid(user.uid);
          } else {
            logger("case2-2");
            logger(uid, user.uid);
            //2-2. 矛盾しているのでログアウト+sessionCookieをもとに再ログイン
            signOut(auth).then(() => {
              loginWithCustomToken(customToken).then((result) => {
                setUid(result);
              });
            });
          }
        } else {
          logger("case2-3");
          //2-3. sessionCookie再発行する
          window.location.href = `https://enigmahouse.jp/refresh/${process.env.REACT_APP_LOGIN_URL}`;
        }
      } else {
        if (uid !== null && customToken !== null) {
          logger("case4-1");
          //4-1. sessionCookieをもとにログイン
          loginWithCustomToken(customToken).then((result) => {
            setUid(result);
          });
        } else {
          logger("case4-2");
          //4-2. ゲストモード
          setUid("");
        }
      }
    });
  }, [loading, auth, user]);

  useEffect(() => {
    switch (uid) {
      case null:
        break;
      case "":
        //ゲストモード
        if (location.pathname === "/firstlogin") {
          navigate("/");
          return;
        }
        break;
      default:
        //ログインユーザー
        if (
          isUserDataLoaded &&
          userData === null &&
          location.pathname !== "/firstlogin"
        ) {
          //アカウント新規作成
          callCreateUser(uid);
          navigate("/firstlogin");
          return;
        }
        if (
          isUserDataLoaded &&
          userData !== null &&
          location.pathname === "/firstlogin"
        ) {
          //ログイン成功
          navigate("/");
          return;
        }
    }
  }, [uid, isUserDataLoaded, location.pathname, userData, navigate]);

  // フロアデータ取得
  useEffect(() => {
    const f = async () => {
      setAllFloorData(await loadAllFloorData());
    };
    f();
  }, []);

  const { revealedFloorNumber } = useRevealedFloorNumber(guildData);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { backgroundColor: "#181053" },
        }}
      />
      {!isUserDataLoaded || uid === null ? (
        <NowLoading />
      ) : (
        <CommonHeader guildData={guildData} userData={userData}>
          <Routes>
            <Route
              path="/"
              element={
                <Wrapper>
                  <FloorSelect
                    revealedFloorNumber={revealedFloorNumber}
                    guildData={guildData}
                    userData={userData}
                    allFloorData={allFloorData}
                  />
                </Wrapper>
              }
            />
            <Route
              path="/floor/:floorIndex"
              element={
                <Wrapper>
                  <FloorPage
                    userData={userData}
                    allFloorData={allFloorData}
                    revealedFloorNumber={revealedFloorNumber}
                  />
                </Wrapper>
              }
            />

            <Route
              path="/config"
              element={
                <Wrapper>
                  <ConfigTop
                    isGuestMode={isGuestMode}
                    isButtonDisabled={isButtonDisabled}
                    userData={userData}
                  />
                </Wrapper>
              }
            />
            <Route
              path="/config/user"
              element={
                <Wrapper>
                  <UserConfig
                    isGuestMode={isGuestMode}
                    isButtonDisabled={isButtonDisabled}
                    setIsButtonDisabled={setIsButtonDisabled}
                    guildData={guildData}
                    userData={userData}
                    uid={uid}
                  />
                </Wrapper>
              }
            />
            <Route
              path="/config/guild"
              element={
                <Wrapper>
                  <GuildConfig
                    guildData={guildData}
                    userData={userData}
                    uid={uid}
                    isButtonDisabled={isButtonDisabled}
                    setIsButtonDisabled={setIsButtonDisabled}
                    isGuestMode={isGuestMode}
                  />
                </Wrapper>
              }
            />
            <Route
              path="/about"
              element={
                <Wrapper>
                  <Rule />
                </Wrapper>
              }
            />
            <Route
              path="/firstlogin"
              element={
                <Wrapper>
                  <FirstLogin
                    isAuthLoading={loading}
                    isMyDataLoaded={isUserDataLoaded}
                    userData={userData}
                    uid={uid}
                  />
                </Wrapper>
              }
            />
            <Route
              path="/firstlogin/success"
              element={
                <Wrapper>
                  <SuccessCreateNewAccount />
                </Wrapper>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </CommonHeader>
      )}
    </ThemeProvider>
  );
};

export default App;

export const Wrapper = (props: { children: ReactNode }) => {
  return (
    <>
      <Box sx={{ width: "100%", textAlign: "center" }}>
        <Link to={"/"}>
          <img
            src={headerVisual}
            style={{ maxHeight: "50vh", maxWidth: "100vw" }}
          ></img>
        </Link>
      </Box>
      <Container maxWidth="xl">{props.children}</Container>
    </>
  );
};
