import { httpsCallable } from "firebase/functions";
import { functions } from "../../..";

export const callReceiveInviteCode = async (
  inviteCode: string,
  oldGuildId: string,
  uid: string
) => {
  const receiveInviteCodeFunction = httpsCallable(
    functions,
    "receiveInviteCode"
  );
  try {
    const result = await receiveInviteCodeFunction({
      inviteCode,
      oldGuildId,
      uid,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};
