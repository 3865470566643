import { httpsCallable } from "firebase/functions";
import { functions } from "../../..";

export const callUpdateUser = async ({
  uid,
  userNameInputValue,
  picture,
}: {
  uid: string;
  userNameInputValue?: string;
  picture?: string;
}) => {
  if (userNameInputValue !== "") {
    const updateNameFunction = httpsCallable(functions, "updateUser");
    try {
      await updateNameFunction({
        newUserName: userNameInputValue,
        uid,
        picture: picture,
      });
      return true;
    } catch (e) {
      console.log(e);
    }
  } else {
    return false;
  }
};
