import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { FC, useState, useEffect } from "react";
import { Floor } from "../../../../../interface";
import { hashText } from "../../../../../util/hashText";

export type ProblemCardContainerProps = {
  allFloorData: Map<string, Floor>;
  isFloorsSolved: boolean[];
  isFloorsSolvedByGuild: boolean[];
  handleDisplayFloor: (floorIndex: number) => void;
};

export type ProblemCardProps = ProblemCardContainerProps & {
  floorNumber: number;
};

export const ProblemCard: FC<ProblemCardProps> = (props) => {
  const {
    allFloorData,
    floorNumber,
    isFloorsSolved,
    isFloorsSolvedByGuild,
    handleDisplayFloor,
  } = props;
  const [floorData, setFloorData] = useState<Floor | null>(null);
  useEffect(() => {
    hashText(floorNumber.toString()).then((hash) => {
      const dataName = hash.slice(0, 8);
      setFloorData(allFloorData.get(dataName) ?? null);
    });
  }, [allFloorData, floorNumber]);

  const color = isFloorsSolved[floorNumber - 1]
    ? "#a5bfc7"
    : isFloorsSolvedByGuild[floorNumber - 1]
    ? "#ac8db8"
    : undefined;
  return (
    <Grid item xs={12} sm={4} key={floorNumber.toString()}>
      <Card sx={{ bgcolor: color }}>
        <CardActionArea onClick={() => handleDisplayFloor(floorNumber)}>
          <CardMedia
            component="img"
            image={floorData?.problemImage}
            alt={floorData?.title}
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {floorData?.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              by {floorData?.author}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
