import { useMemo } from "react";
import { floorsCount } from "../App";
import { Guild, User } from "../interface";

export const useProgress = (userData: User | null, guildData: Guild | null) => {
  const isGuildSolved109Problem = useMemo(() => {
    return guildData !== null && guildData.floors["109"];
  }, [guildData]);
  const isFloorsSolved = useMemo(() => {
    if (userData === null) {
      return Array(floorsCount + 1).fill(false);
    }
    const ret = Array(floorsCount + 1)
      .fill(false)
      .map(
        (_, floorIndex) =>
          !(userData.floors[(floorIndex + 1).toString()] === undefined)
      );
    return ret;
  }, [userData]);
  const isFloorsSolvedByGuild = useMemo(() => {
    if (guildData === null) {
      return Array(floorsCount + 1).fill(false);
    }
    const ret = Array(floorsCount + 1)
      .fill(false)
      .map(
        (_, floorIndex) =>
          !(guildData.floors[(floorIndex + 1).toString()] === undefined)
      );
    return ret;
  }, [guildData]);
  const isGuildSolved108Problem = useMemo(
    () =>
      isFloorsSolvedByGuild.slice(0, floorsCount).every((isSolved) => isSolved),
    [isFloorsSolvedByGuild]
  );

  return {
    isGuildSolved109Problem,
    isFloorsSolved,
    isFloorsSolvedByGuild,
    isGuildSolved108Problem,
  };
};
