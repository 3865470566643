import { httpsCallable } from "firebase/functions";
import { functions } from "../../..";

export const callCheckAnswer = async (floorId: string) => {
  let returnData: { isCorrect: boolean } = {
    isCorrect: false,
  };
  const checkAnswerFunction = httpsCallable(functions, "checkAnswer");
  try {
    await checkAnswerFunction({
      floorId: floorId,
    }).then((result) => {
      const data: any = result.data;
      returnData.isCorrect = data?.isCorrect;
    });
  } catch (e) {
    throw e;
  }
  return returnData;
};
