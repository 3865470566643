import { Button, Grid, Stack } from "@mui/material";
import { fabric } from "fabric";
import { useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";

type Prop = {
  clear: () => void;
  undo: () => void;
  redo: () => void;
};

export const CanvasButtons = (prop: Prop) => {
  const { undo, redo, clear } = prop;
  const buttonArray = [
    //ドラッグモードの需要がないと思われるので削除
    //{ name: "Drag Mode", function: makeDrawingModeOff },
    //{ name: "Pen Mode", function: makeDrwaingModeOn },
    { name: "clear", function: clear, startIcon: <DeleteIcon /> },
    { name: "undo", function: undo, startIcon: <UndoIcon /> },
    { name: "redo", function: redo, startIcon: <RedoIcon /> },
  ];

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0.5}
    >
      {buttonArray.map((v) => (
        <Button
          variant="contained"
          color="primary"
          onClick={v.function}
          sx={{ mx: 0.5 }}
          key={v.name}
          startIcon={v.startIcon}
        >
          {v.name}
        </Button>
      ))}
    </Stack>
  );
};
