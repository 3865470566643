import { httpsCallable } from "firebase/functions";
import { functions } from "../../..";

export const callKickGuildMember = async (uid: string, kickUid: string) => {
  const kickGuildMemberFunction = httpsCallable(functions, "kickGuildMember");
  try {
    const result = await kickGuildMemberFunction({ kickUid, uid });
    return result;
  } catch (e) {
    console.log(e);
  }
};
