import { httpsCallable } from "firebase/functions";
import { functions } from "../../..";

export const callMakeInviteCode = async (uid: string) => {
  const makeInviteCodeFunction = httpsCallable(functions, "makeInviteCode");
  try {
    const result = await makeInviteCodeFunction({ uid: uid });
    const data: any = result.data;
    if (data?.success) {
      return true;
    } else {
      console.log(data?.reason);
      return false;
    }
  } catch (e) {
    console.log(e);
  }
};
