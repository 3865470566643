import LinearProgress from "@mui/material/LinearProgress";
import { User } from "../../../../interface";

import { NowLoading } from "../../static/NowLoading";

//firebase authにはログインしているがfirestore上のデータがない場合にのみ表示するページ
export const FirstLogin = ({
  isAuthLoading,
  isMyDataLoaded,
  userData,
  uid,
}: {
  isAuthLoading: boolean;
  isMyDataLoaded: boolean;
  userData: User | null;
  uid: string;
}) => {
  const isLogined = uid !== "";
  return (
    <>
      {isLogined && isMyDataLoaded && userData === null ? (
        <>
          <LinearProgress />
          <p>アカウント作成中です...</p>
        </>
      ) : isAuthLoading || !isMyDataLoaded ? (
        <NowLoading />
      ) : !isLogined ? (
        <div>アカウント作成にはログインが必要です。</div>
      ) : (
        <div>
          既にアカウントは作成されています。アカウント作成について問題が生じた場合はお問い合わせよりご連絡ください。
        </div>
      )}
    </>
  );
};
