export const monthNameList = [
  "1月 -睦月- はじまり",
  "2月 -如月- 天体観測",
  "3月 -弥生- 四角形",
  "4月 -卯月- 技巧",
  "5月 -皐月- 日常",
  "6月 -水無月- 折り返し ",
  "7月 -文月- カワイイ",
  "8月 -葉月- 赤と青",
  "9月 -長月- シンプル",
  "10月 -神無月- 難問",
  "11月 -霜月- ◾︎◾︎◾︎◾︎◾︎◾︎◾︎◾︎◾︎ ",
  "12月 -師走- おわりに",
  "年越し謎2022ex",
];
