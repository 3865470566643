import { Button, Grid, Icon, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { User } from "../../../../interface";
import { HomeButton } from "../../common/button/HomeButton";

export const ConfigTop = (props: {
  isGuestMode: boolean;
  isButtonDisabled: boolean;
  userData: User | null;
}) => {
  const navigate = useNavigate();
  if (props.userData === null) {
    return <p>各種設定はログイン後利用できます。</p>;
  }
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4" component="h1">
          各種設定
        </Typography>
        <Button
          variant="contained"
          startIcon={<Icon>person</Icon>}
          onClick={() => navigate("/config/user")}
        >
          ユーザー設定
        </Button>
        {!props.isGuestMode && (
          <Button
            variant="contained"
            startIcon={<Icon>group</Icon>}
            onClick={() => navigate("/config/guild")}
          >
            ギルド設定
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <HomeButton isButtonDisabled={props.isButtonDisabled} />
      </Grid>
    </>
  );
};
