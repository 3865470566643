import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../..";
import { Floor, Guild, User, UserWithId } from "../../interface";
import { hashText } from "../../util/hashText";
import {
  floorConverter,
  guildConverter,
  userConverter,
} from "./lib/firestoreConverter";

export const getUserParentUid = (uid: string): Promise<string> => {
  if (uid === "") {
    return Promise.resolve("");
  }
  const colref = collection(db, "user");
  const docref = doc(colref, uid);
  return getDoc(docref)
    .then((doc) => {
      if (doc.exists()) {
        return doc.data()?.parentUid;
      } else {
        return undefined;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

export const loadUserData = (
  uid: string | undefined,
  setUserData: (userData: User) => void,
  setUserDataLoaded: (arg: boolean) => void
) => {
  if (uid === undefined || uid === "") {
    return () => {};
  }
  const colref = collection(db, "user");
  const docref = doc(colref, uid).withConverter(userConverter);

  let success = false;
  const stopUserDataSnapshot = onSnapshot(docref, (doc) => {
    const newUserData = doc.data();
    if (newUserData) {
      setUserData(newUserData);
      success = true;
    } else {
      console.log("No such document!");
    }
    setUserDataLoaded(true);
  });
  return stopUserDataSnapshot;
};

export const loadAllUserData = (setAllUserData: (g: UserWithId[]) => void) => {
  const colRef = collection(db, "user").withConverter(userConverter);
  let success = false;
  const stopUserDataSnapshot = getDocs(colRef).then((col) => {
    const allUserData = col.docs.map((doc) => ({
      userName: doc.data()?.userName,
      guildId: doc.data()?.guildId,
      joinedGuildId: doc.data()?.joinedGuildId,
      solvedCount: doc.data()?.solvedCount,
      floors: doc.data()?.floors,
      picture: doc.data()?.picture,
      uid: doc.id,
    }));
    setAllUserData(allUserData);
  });
  return stopUserDataSnapshot;
};

export const loadGuildData = (
  guildId: string,
  setGuildData: (guildData: Guild | null) => void
) => {
  if (guildId === "") {
    return () => {};
  }
  const colref = collection(db, "guild");
  const docRef = doc(colref, guildId).withConverter(guildConverter);
  let success = false;
  const stopGuildDataSnapshot = onSnapshot(docRef, (doc) => {
    const newGuildData = doc.data();
    if (newGuildData) {
      setGuildData(newGuildData);
      success = true;
    } else {
      setGuildData(null);
      console.log("No such document!");
    }
  });
  return stopGuildDataSnapshot;
};

export const loadAllGuildData = (setAllGuildData: (g: Guild[]) => void) => {
  const colRef = collection(db, "guild").withConverter(guildConverter);
  let success = false;
  const stopGuildDataSnapshot = getDocs(colRef).then((col) => {
    const allGuildData = col.docs.map((doc) => doc.data());
    setAllGuildData(allGuildData);
  });
  return stopGuildDataSnapshot;
};

export const loadRankingMetaData = async (
  setRankingMeta: (rankingMetaData: { ranking: Date }) => void
) => {
  const docRef = doc(db, "doc", "ranking");
  getDoc(docRef).then((doc) => {
    const data = {
      ranking: doc.data()?.ranking.toDate(),
    };
    setRankingMeta(data);
  });
};

//floorには問題URLのみを保存する
export const loadFloorData = async (
  floorName: string,
  setFloorData: (arg: Floor) => void
) => {
  const floorId = (await hashText(floorName)).slice(0, 8);
  const docRef = doc(db, "floor", floorId).withConverter(floorConverter);
  getDoc(docRef)
    .then(async (doc) => {
      const newFloorData = doc.data();
      if (newFloorData) {
        setFloorData(newFloorData);
      } else {
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

//TODO これだとrevealedでない問題のデータも読み込んでしまっている
export const loadAllFloorData = async () => {
  const colRef = collection(db, "floor").withConverter(floorConverter);
  const allFloorData = new Map<string, Floor>();
  try {
    getDocs(colRef).then((col) => {
      col.docs.map((doc) => {
        const data = doc.data();
        const id = doc.id;
        allFloorData.set(id, data);
      });
    });
    return allFloorData;
  } catch (e) {
    console.log(e);
    return allFloorData;
  }
};

export const loadGuildAdminData = async (
  guildId: string,
  setGuildInviteCode: (inviteCode: string) => void
) => {
  if (guildId === "") return false;
  const docRef = doc(db, "guildAdmin", guildId);
  return await getDoc(docRef)
    .then((doc) => {
      if (doc.exists()) {
        const newInviteCode: string = doc.data()?.inviteCode;
        if (newInviteCode) {
          setGuildInviteCode(newInviteCode);
          return true;
        } else {
          console.log("no data :doc.data().?inviteCode");
          return false;
        }
      } else {
        console.log("No such document!");
        return false;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      return false;
    });
};

export const getRevealedFloorNumber = async () => {
  const docRef = doc(db, "revealedFloorNumber", "revealedFloorNumber");
  const data = await getDoc(docRef).catch((error) => {
    console.log("Error getting document:", error);
  });
  if (data) {
    if (typeof data.data()?.revealedFloorNumber === "number") {
      return data.data()?.revealedFloorNumber;
    } else {
      console.log("No revealedFloorNumber or type error");
    }
  }
};

export const getUserName = async (memberId: string) => {
  const docRef = doc(db, "user", memberId);
  const memberName = await getDoc(docRef).then((doc) => {
    let newMemberName = "";
    if (doc.exists()) {
      newMemberName = doc.data()?.userName;
    }
    return newMemberName;
  });
  return { memberName, memberId };
};

export const getUserDataOnce = async (uid: string) => {
  if (uid === "") return undefined;
  const docRef = doc(db, "user", uid).withConverter(userConverter);
  const userData = (await getDoc(docRef)).data();
  return userData;
};

export const getIsGuildRevealed109Problem = async (
  guildMemberIds: string[]
) => {
  const userColRef = collection(db, "user");
  let ret = false;
  //console.log("guildmember", guildMemberIds);
  const promiseArray: Array<Promise<any>> = [];
  guildMemberIds.forEach((v) => {
    if (v ?? "" !== "") {
      promiseArray.push(
        getDoc(doc(db, "user", v).withConverter(userConverter))
      );
    }
  });
  const result = await Promise.allSettled(promiseArray);
  result.forEach((v) => {
    if (v.status == "fulfilled") {
      const userData: User = v.value.data();
      //console.log(userData?.userName, userData?.is109FloorRevealed);
      if (userData?.is109FloorRevealed) {
        ret = true;
      }
    }
  });
  //  console.log(ret);
  return ret;
};
