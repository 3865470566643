import {
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { ChangeUserName } from "./contents/ChangeUserName";
import { PageTitle } from "../../common/typography/PageTitle";
import { Guild, User } from "../../../../interface";
import { ChangeUserPicture } from "./contents/ChangeUserPicture";

type UserConfigProps = {
  isGuestMode: boolean;
  isButtonDisabled: boolean;
  setIsButtonDisabled: (prop: boolean) => void;
  guildData: Guild | null;
  userData: User | null;
  uid: string;
};
export const UserConfig = (props: UserConfigProps) => {
  if (props.userData === null) {
    return <p>各種設定はログイン後利用できます。</p>;
  }

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={props.isButtonDisabled}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={12}>
        <PageTitle>ユーザー設定</PageTitle>
      </Grid>

      <Card>
        <CardContent>
          <Typography variant="h4" component="h1">
            ユーザー情報
          </Typography>
          <p>アカウント名: {props.userData.userName}</p>
          <p>ユーザーID: {props.uid}</p>
          {props.guildData !== null && <p>ギルド名: {props.guildData.name}</p>}
          <p>個人正解数: {props.userData.solvedCount}</p>
          {props.guildData !== null && (
            <p>ギルド正解数: {props.guildData.solvedCount}</p>
          )}
        </CardContent>
      </Card>
      <ChangeUserName
        uid={props.uid}
        setIsButtonDisabled={props.setIsButtonDisabled}
        isGuestMode={props.isGuestMode}
      />
      <ChangeUserPicture
        uid={props.uid}
        setIsButtonDisabled={props.setIsButtonDisabled}
        isGuestMode={props.isGuestMode}
      />
    </>
  );
};
