import { useState, useEffect } from "react";
export const useIsGuestMode = (uid: string | null) => {
  const [isGuestMode, setIsGuestMode] = useState(false);
  useEffect(() => {
    if (uid === null) {
      return;
    }

    if (uid === "") {
      setIsGuestMode(true);
      return;
    }
    setIsGuestMode(false);
  }, [uid]);
  return isGuestMode;
};
