import { Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { callUpdateGuildName } from "../../../../../api/cloudFunctions/guild/callUpdateGuildName";

export const ChangeGuildName = ({
  setIsButtonDisabled,
  isNewGuild,
  uid,
}: {
  setIsButtonDisabled: (arg: boolean) => void;
  isNewGuild: boolean;
  uid: string;
}) => {
  const [guildNameInputValue, setGuildNameInputValue] = useState("");
  const [message, setMessage] = useState("");

  const clearMessage = () => {
    setMessage("");
  };
  const handleGuildNameInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    clearMessage();
    const newName = e.target.value;
    setGuildNameInputValue(newName);
  };
  const handleButton = async () => {
    setIsButtonDisabled(true);
    setMessage("saved.");
    await callUpdateGuildName(uid, guildNameInputValue, setMessage);
    setIsButtonDisabled(false);
  };

  return (
    <Grid item xs={12} sx={{ my: 2 }}>
      {isNewGuild && <p>ギルド名を入力することで新規ギルドを作成できます。</p>}
      <TextField
        variant="outlined"
        label="ギルド名"
        value={guildNameInputValue}
        onChange={handleGuildNameInputValue}
      ></TextField>

      <Grid item xs={12} component="div" sx={{ my: 2 }}>
        <Button variant="contained" color="primary" onClick={handleButton}>
          {isNewGuild ? "ギルドを新規作成" : "変更を保存する"}
        </Button>
        <div>{message}</div>
      </Grid>
    </Grid>
  );
};
