import { httpsCallable } from "firebase/functions";
import { functions } from "../../..";

export const callCreateUser = async (uid: string) => {
  const createUserFunction = httpsCallable(functions, "createUser");
  try {
    const result = (
      await createUserFunction({
        uid,
      })
    ).data;
    return result;
  } catch (e) {
    console.log(e);
    return false;
  }
};
