import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppsIcon from "@mui/icons-material/Apps";
export const HomeButton = ({
  isButtonDisabled,
}: {
  isButtonDisabled?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <Button
      variant="contained"
      disabled={isButtonDisabled}
      startIcon={<AppsIcon />}
      onClick={() => navigate("/")}
    >
      年越し謎2022トップに戻る
    </Button>
  );
};
