import { Button, Grid, Icon, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { logger } from "../../../../../lib/logger";
import { hashText } from "../../../../../util/hashText";

const toHalfWidth = (strVal: string) => {
  // 半角変換
  const halfVal = strVal.replace(/[！-～]/g, (tmpStr: string) => {
    // 文字コードをシフト
    return String.fromCharCode(tmpStr.charCodeAt(0) - 0xfee0);
  });

  // 文字コードシフトで対応できない文字の変換
  return halfVal
    .replace(/”/g, '"')
    .replace(/’/g, "'")
    .replace(/‘/g, "`")
    .replace(/￥/g, "\\")
    .replace(/　/g, " ")
    .replace(/〜/g, "~");
};

const AnswerKeyInput = ({
  floorId,
  answerArray,
  handleCollectAnswer,
  handleWrongAnswer,
  specialArray,
  specialText,
}: {
  floorId: string;
  answerArray: string[];
  handleCollectAnswer: () => Promise<void>;
  handleWrongAnswer: (text?: string) => Promise<void>;
  specialArray?: string[];
  specialText?: string;
}) => {
  const [answerKeyInputValue, setAnswerKeyInputValue] = useState("");
  const [alreadyInput, setAlreadyInput] = useState(false);

  useEffect(() => {
    const savedKey = window.localStorage.getItem(floorId);
    if (savedKey) {
      setAlreadyInput(true);
      setAnswerKeyInputValue(savedKey);
    } else {
      setAlreadyInput(false);
      setAnswerKeyInputValue("");
    }
  }, [floorId]);

  const handleAnswerKeyInputValue = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newAnswerKeyInputValue = e.target.value;
    setAnswerKeyInputValue(newAnswerKeyInputValue);
  };

  const checkAnswerKey = async () => {
    const hashedAnswerKey = await hashText(answerKeyInputValue);
    logger(answerArray, hashedAnswerKey);
    if (answerArray.includes(hashedAnswerKey) && answerKeyInputValue !== "") {
      await handleCollectAnswer();
      window.localStorage.setItem(floorId, answerKeyInputValue);
      setAlreadyInput(true);
    } else {
      if (specialArray && specialText) {
        if (specialArray.includes(hashedAnswerKey)) {
          handleWrongAnswer(specialText);
          return;
        }
      }
      handleWrongAnswer();
    }
  };

  return (
    <Grid component="div" sx={{ mx: 1, my: 2 }}>
      <TextField
        type="text"
        value={answerKeyInputValue}
        label="Answer Key"
        disabled={alreadyInput}
        variant="outlined"
        onChange={(e) => handleAnswerKeyInputValue(e)}
        sx={{ mx: 1, my: 1 }}
      ></TextField>
      <Button
        variant="contained"
        onClick={checkAnswerKey}
        endIcon={<Icon>send</Icon>}
        sx={{ my: 2 }}
        size={"large"}
      >
        解答
      </Button>
    </Grid>
  );
};
export default AnswerKeyInput;
