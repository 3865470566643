import { Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { callReceiveInviteCode } from "../../../../../api/cloudFunctions/guild/callReceiveInviteCode";

export const ReceiveGuildInviteCode = ({
  setIsButtonDisabled,
  guildId,
  isSolo,
  uid,
}: {
  setIsButtonDisabled: (arg: boolean) => void;
  guildId: string;
  isSolo: boolean;
  uid: string;
}) => {
  const [inviteCode, setInviteCode] = useState("");
  const handleInviteCode = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInviteCode(e.target.value);
    setMessage("");
  };

  const [message, setMessage] = useState("");

  const handleButton = async () => {
    setIsButtonDisabled(true);
    const result = await callReceiveInviteCode(inviteCode, guildId, uid);
    const data: any = result?.data;
    if (!data?.success) {
      setMessage(
        "ギルドに参加できませんでした。招待コードが正しいか、また、招待コードが有効かお確かめください。このメッセージが複数回出る場合、時間をおいてお試しください。"
      );
    } else {
      setMessage("ギルドに参加しました。");
    }
    setIsButtonDisabled(false);
  };
  return (
    <Grid item xs={12}>
      <p>招待コードを利用して既存のギルドに参加できます。</p>
      <Grid item>
        <TextField
          value={inviteCode}
          label="招待コード"
          variant="outlined"
          onChange={(e) => {
            handleInviteCode(e);
          }}
        ></TextField>
      </Grid>
      <Grid item xs={12} component="div" sx={{ my: 2 }}>
        {isSolo ? (
          <Button variant="contained" onClick={() => handleButton()}>
            ギルドに参加
          </Button>
        ) : (
          <>
            <Button variant="contained" onClick={() => handleButton()}>
              ギルドを併合
            </Button>
          </>
        )}
        <div>{message}</div>
      </Grid>
    </Grid>
  );
};
