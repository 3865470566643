import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getRevealedFloorNumber } from "../api/firestore/dataLoader";
import { floorsCount } from "../App";
import { Guild } from "../interface";

export const useRevealedFloorNumber = (guildData: Guild | null) => {
  /*
  const { isGuildRevealed109Problem } = useIsGuildRevealed109Problem(guildData);
  const [revealedFloorNumber, setRevealedFloorNumber] = useState(-1);
  const location = useLocation();
  // 何問目まで解禁されているか。
  // ユーザーが109問目を解禁したか。
  useEffect(() => {
    console.log(revealedFloorNumber, isGuildRevealed109Problem);
    if (isGuildRevealed109Problem) {
      if (revealedFloorNumber !== 109) {
        setRevealedFloorNumber(109);
      }
      return;
    }
    getRevealedFloorNumber().then((newrevealedFloorNumber) => {
      if (newrevealedFloorNumber) {
        setRevealedFloorNumber(newrevealedFloorNumber);
      }
    });
  }, [location.pathname, isGuildRevealed109Problem]);
  */
  return { revealedFloorNumber: 109 };
};

const useIsGuildRevealed109Problem = (guildData: Guild | null) => {
  const [isGuildRevealed109Problem, setIsGuildRevealed109Problem] =
    useState(false);
  useEffect(() => {
    if (guildData === null) {
      return;
    }
    //109問目解禁条件が特定のボタンを押す等の特殊処理の場合
    /*
    getIsGuildRevealed109Problem(guildData?.memberIds).then(
      (isGuildRevealed109Problem) => {
        setIsGuildRevealed109Problem(isGuildRevealed109Problem);
      }
    );
    */
    //109問目解禁条件が108問を解いたという場合

    const range = (stop: number) =>
      new Array<string>(stop).fill("").map((_, i) => (i + 1).toString());
    const temp: boolean = range(floorsCount).reduce(
      (pre: boolean, cur): boolean =>
        guildData.floors?.[cur] !== undefined && pre,
      true
    );
    console.log(temp);
    setIsGuildRevealed109Problem(temp);
  }, [guildData]);
  return { isGuildRevealed109Problem };
};
