import { httpsCallable } from "firebase/functions";
import { functions } from "../../..";

export const callLeaveGuild = async (uid: string) => {
  const leaveFromGuildFunction = httpsCallable(functions, "leaveGuild");
  try {
    const result = await leaveFromGuildFunction({ uid });
    return result;
  } catch (e) {
    console.log(e);
  }
};
