import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { logger } from "../../lib/logger";
export const uploadImage = async (image: File, imageName: string) => {
  logger("uploadImage");
  const storage = getStorage();
  const storageRef = ref(storage, `${imageName}`);
  try {
    const result = await uploadBytes(storageRef, image);
    logger(result);
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (e) {
    logger(e);
    return null;
  }
};
