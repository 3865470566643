import { httpsCallable } from "firebase/functions";
import { functions } from "../../..";

export const callChangeGuildOwner = async (
  uid: string,
  guildId: string,
  newOwnerId: string
) => {
  const changeGuildOwnerFunction = httpsCallable(functions, "changeGuildOwner");
  try {
    const result = await changeGuildOwnerFunction({
      uid: uid,
      guildId: guildId,
      newOwnerId: newOwnerId,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};
