import { useEffect, useMemo, useRef, useState } from "react";
import { getUserDataOnce, loadUserData } from "../api/firestore/dataLoader";
import { floorsCount } from "../App";
import { User } from "../interface";
export const useUserData = (uid: string | null) => {
  const [userData, setUserData] = useState<User | null>(null);
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const stopUserDataSnapshotRef = useRef(() => {});
  useEffect(() => {
    if (uid === null) {
      return;
    }
    if (uid === "") {
      setIsUserDataLoaded(true);
      return;
    }
    stopUserDataSnapshotRef.current();
    getUserDataOnce(uid).then((newUserData) => {
      stopUserDataSnapshotRef.current = loadUserData(
        uid,
        setUserData,
        setIsUserDataLoaded
      );
    });
  }, [uid]);
  const isFloorsSolved = useMemo(() => {
    if (userData === null) {
      return Array(floorsCount + 1).fill(false);
    }
    const ret = Array(floorsCount + 1)
      .fill(false)
      .map(
        (_, floorIndex) =>
          !(userData.floors[(floorIndex + 1).toString()] === undefined)
      );
    return ret;
  }, [userData]);

  return { userData, isUserDataLoaded, isFloorsSolved };
};
