const extensionList = [
  { type: "image/png", extension: ".png" },
  { type: "image/jpeg", extension: ".jpg" },
];

export const makeImageName = (file: File) => {
  let extension = "";
  for (const v of extensionList) {
    if (file.type === v.type) {
      extension = v.extension;
    }
  }
  const now = new Date();
  return (
    now.getFullYear().toString() +
    ("0" + (now.getMonth() + 1).toString()).slice(-2) +
    ("0" + now.getDate().toString()).slice(-2) +
    ("0" + now.getHours().toString()).slice(-2) +
    ("0" + now.getMinutes().toString()).slice(-2) +
    ("0" + now.getSeconds().toString()).slice(-2) +
    "_" +
    Math.floor(Math.random() * 10000).toString() +
    extension
  );
};
