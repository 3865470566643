import TwitterIcon from "@mui/icons-material/Twitter";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { callCheckAnswer } from "../../../../api/cloudFunctions/problem/callCheckAnswer";
import { Floor, User } from "../../../../interface";
import { tweet } from "../../../../lib/tweets";
import { hashText } from "../../../../util/hashText";
import { HomeButton } from "../../common/button/HomeButton";
import { Canvas } from "../../common/canvas/Canvas";
import { CorrectDialog } from "../../common/dialog/CorrectDialog";
import { WrongDialog } from "../../common/dialog/WrongDialog";
import { PageTitle } from "../../common/typography/PageTitle";
import { NotFound } from "../../static/NotFound";
import AnswerKeyInput from "./contents/AnswerKeyInput";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const SolutionArea = React.memo(
  ({ solutionImage }: { solutionImage: string }) => {
    return (
      <Grid component="div" sx={{ my: 1 }}>
        <img src={solutionImage} width="100%" height="auto"></img>
      </Grid>
    );
  }
);

const ProblemArea = React.memo(
  ({
    floorData,
    floorIndex,
    handleCollectAnswer,
    handleWrongAnswer,
  }: {
    floorData: Floor;
    floorIndex: number;
    handleCollectAnswer: () => Promise<void>;
    handleWrongAnswer: (text?: string) => Promise<void>;
  }) => {
    return (
      <>
        <Grid component="div" sx={{ my: 1 }}>
          <Canvas imageUrl={floorData.problemImage} />
        </Grid>

        <AnswerKeyInput
          floorId={(floorIndex + 1).toString()}
          answerArray={floorData.answerArray}
          specialArray={floorData.specialArray}
          specialText={floorData.specialText}
          handleCollectAnswer={handleCollectAnswer}
          handleWrongAnswer={handleWrongAnswer}
        />
      </>
    );
  }
);

export const FloorPage = ({
  revealedFloorNumber,
  userData,
  allFloorData,
}: {
  revealedFloorNumber: number;
  userData: User | null;
  allFloorData: Map<string, Floor>;
}) => {
  const temp = useParams<{ floorIndex: string }>();
  const floorIndex = temp.floorIndex;

  const [monthName, setMonthName] = useState("");
  const [floorNumFromZero, setFloorNumFromZero] = useState(-1); //0-index
  const [isFloorSolved, setIsFloorSolved] = useState(false);
  const [monthNum, setMonthNum] = useState(0);
  const [problemNum, setProblemNum] = useState(0);
  const [floorData, setFloorData] = useState<Floor | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (floorIndex === undefined) {
      return;
    }
    hashText(floorIndex).then((hash) => {
      const dataName = hash.slice(0, 8);
      const newFloorData = allFloorData.get(dataName);
      if (newFloorData) {
        setFloorData(newFloorData);
      }
    });
    setTabValue("problem");
  }, [allFloorData, floorIndex]);

  //floorDataを持ってくる

  useEffect(() => {
    if (floorIndex === undefined) {
      return;
    }
    const tempFloorIndexNum = parseInt(floorIndex, 10);
    setFloorNumFromZero(tempFloorIndexNum - 1);
    const monthNum = Math.ceil(tempFloorIndexNum / 9);
    setMonthNum(monthNum);
    setProblemNum(tempFloorIndexNum - 9 * (monthNum - 1));
    if (monthNum <= 12) {
      setMonthName(
        `${monthNum.toString()}月‐${(
          tempFloorIndexNum -
          9 * (monthNum - 1)
        ).toString()}`
      );
    } else {
      setMonthName("年越し謎2022ex");
    }
  }, [floorIndex]);

  const handleChallengeTweet = () => {
    tweet({
      url:
        monthName === "年越し謎2022ex"
          ? "https://goodbye2022.enigmahouse.jp/"
          : `https://goodbye2022.enigmahouse.jp/share/2022-${monthNum.toString()}-${problemNum.toString()}-${
              url[floorNumFromZero]
            }`,
      text: `${monthName} ${floorData?.title}に挑戦します！`,
      hashtag: "年越し謎2022",
    });
  };

  const handleSolvedTweet = () => {
    tweet({
      url:
        monthName === "年越し謎2022ex"
          ? "https://goodbye2022.enigmahouse.jp/"
          : `https://goodbye2022.enigmahouse.jp/share/2022-${monthNum.toString()}-${problemNum.toString()}-${
              url[floorNumFromZero]
            }`,
      text: `${monthName} ${floorData?.title}を見事解き明かした！`,
      hashtag: "年越し謎2022",
    });
  };

  const [tabValue, setTabValue] = React.useState("problem");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    if (userData === null) {
      setIsFloorSolved(false);
      return;
    }
    if (userData.floors[floorIndex?.toString() ?? ""]) {
      setIsFloorSolved(true);
    } else {
      setIsFloorSolved(false);
    }
  }, [userData, userData?.floors, floorIndex]);

  const [successOpen, setSuccessOpen] = useState(false);
  const [wrongOpen, setWrongOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [dialogText, setDialogText] = useState<string | undefined>(undefined);

  const handleCollectAnswer = async () => {
    setIsButtonDisabled(true);
    const result = await callCheckAnswer((floorNumFromZero + 1).toString());
    setIsFloorSolved(result.isCorrect);
    setTabValue("solution");
    setSuccessOpen(true);
    setIsButtonDisabled(false);
  };
  const handleWrongAnswer = async (text?: string) => {
    setDialogText(text);
    setWrongOpen(true);
  };

  const handleClose = () => {
    setSuccessOpen(false);
    setWrongOpen(false);
  };

  if (floorData === undefined) {
    return <NotFound />;
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isButtonDisabled}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {floorData && revealedFloorNumber > floorNumFromZero ? (
        <Paper>
          <p>{monthName}</p>
          <PageTitle>{floorData.title}</PageTitle>
          <div>
            by{" "}
            {floorData.authorTwitterAccount ? (
              <a
                href={`https://twitter.com/${floorData.authorTwitterAccount}`}
                style={{ color: "#fff" }}
              >
                {floorData.author}
              </a>
            ) : (
              <>{floorData.author}</>
            )}
          </div>
          <Typography variant="body2">{floorData.comment}</Typography>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab label="問題" value="problem" />
                <Tab
                  label="解説"
                  value="solution"
                  disabled={false /*!isFloorSolved*/}
                />
              </TabList>
            </Box>
            <TabPanel value="problem">
              <ProblemArea
                floorIndex={floorNumFromZero}
                handleWrongAnswer={handleWrongAnswer}
                handleCollectAnswer={handleCollectAnswer}
                floorData={floorData}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ my: 1 }}
                onClick={() => {
                  window.open(floorData.problemImage, floorData.title + "問題");
                }}
              >
                別タブで画像を表示する
              </Button>
            </TabPanel>
            <TabPanel value="solution">
              <SolutionArea solutionImage={floorData.solutionImage} />
              <Button
                variant="contained"
                color="primary"
                sx={{ my: 1 }}
                onClick={() => {
                  window.open(
                    floorData.solutionImage,
                    floorData.title + "解説"
                  );
                }}
              >
                別タブで画像を表示する
              </Button>
            </TabPanel>
          </TabContext>

          {isFloorSolved ? (
            <Button
              variant="contained"
              startIcon={<TwitterIcon />}
              color="info"
              sx={{ my: 1 }}
              onClick={handleSolvedTweet}
            >
              正解した事をツイート
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<TwitterIcon />}
              color="info"
              sx={{ my: 1 }}
              onClick={handleChallengeTweet}
            >
              挑戦する事をツイート
            </Button>
          )}
          <Stack direction="row" sx={{ my: 1 }} spacing={1}>
            {floorNumFromZero > 0 && (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(`/floor/${floorNumFromZero}`);
                }}
                startIcon={<ArrowBackIcon />}
              >
                前の謎へ
              </Button>
            )}
            {revealedFloorNumber > floorNumFromZero + 1 && (
              <Button
                variant="contained"
                onClick={() => {
                  navigate(`/floor/${floorNumFromZero + 2}`);
                }}
                endIcon={<ArrowForwardIcon />}
              >
                次の謎へ
              </Button>
            )}
          </Stack>
          <HomeButton />
        </Paper>
      ) : (
        <NotFound />
      )}

      <CorrectDialog open={successOpen} closeHandler={handleClose}>
        {monthName} {floorData?.title}を見事解き明かした！
        <br />
        正解したことをシェアできます。
        <br />
        <Button
          variant="contained"
          startIcon={<TwitterIcon />}
          color="info"
          sx={{ my: 1 }}
          onClick={handleSolvedTweet}
        >
          ツイート
        </Button>
      </CorrectDialog>
      <WrongDialog
        open={wrongOpen}
        closeHandler={handleClose}
        text={dialogText}
      >
        もう一度お考えください。
      </WrongDialog>
    </>
  );
};

const url = [
  "SJ36cxLE",
  "h33JGcVE",
  "sXAkPcX0",
  "nlbmW37B",
  "lKkTyi2U",
  "uS8lcTzq",
  "kZa4W3pT",
  "jKrdnQpR",
  "JDyFlXXH",
  "EnRgvhQP",
  "0iN5Fj8j",
  "RdMryM5b",
  "xuw7XORP",
  "GgdO7Swz",
  "sN5PkcEd",
  "PMFUYaCo",
  "Wi9tzPGL",
  "KSTBFYkG",
  "9CoPiHUa",
  "fJmHn7Nr",
  "hirptFFR",
  "iIFRFoKX",
  "pRH2KZcT",
  "vawuDaVD",
  "Caxs5OjH",
  "X5PMOGmA",
  "6yRAG8bd",
  "KmdFBnJW",
  "qWOLmJjg",
  "C5LcoUmT",
  "pUZJdTi5",
  "Ag8xcpyk",
  "TNjvJG9E",
  "CISu1gmo",
  "0Qd25Ym1",
  "3kaNH6Lq",
  "vjFKaTED",
  "ppHGxno3",
  "qHxPrkmD",
  "Iz5nm9pu",
  "YQCuHxwV",
  "hChRkudW",
  "Ce7UMWCx",
  "6VBrg26v",
  "t9P4X30z",
  "tRNMF9QT",
  "SWEumA4q",
  "sJBAkidG",
  "bgWE8FVK",
  "rLsNvZmC",
  "Uq5pB7Xs",
  "zyEqJXh6",
  "XBW568QJ",
  "nCCWlRV0",
  "egzyVLyR",
  "3kJirgDA",
  "lnH0OQB7",
  "71anz94D",
  "JVBBIPpI",
  "Gxij2DoU",
  "Q9J5W1Yl",
  "3Na0NmbA",
  "E4zWECya",
  "5TtCRTud",
  "mMoHLhHk",
  "zcGkBYqv",
  "w0jRbwdK",
  "I3BTgIdI",
  "0t4ImrEz",
  "vl0DwzER",
  "Hta2kvzV",
  "1JW7emPv",
  "ZeOxXwnh",
  "XRVle4Gs",
  "SA7cFHV2",
  "O1KAfDrS",
  "Xn9syYLU",
  "oUqG0sYm",
  "Z9e7Ej1m",
  "ZTv9k5cA",
  "oy8fUj1T",
  "ThAyqufM",
  "i7pK6NCs",
  "u4lMluHG",
  "sIGWANYB",
  "LQCElTDu",
  "NkcKWinO",
  "p2XhFBGg",
  "RA7YpxoA",
  "gimT5DVh",
  "Mxa8iaXF",
  "rnskW2RM",
  "cf1q5IT3",
  "H0O2Vz5O",
  "KCaeG2m8",
  "AkHJey9n",
  "p1gF89KB",
  "Jh3xJe4E",
  "u89SCSux",
  "NuhICRBQ",
  "uBl9X2ed",
  "VJpL2XRx",
  "uY3Uin44",
  "qoRH7ulf",
  "wnVvj01t",
  "v0Li7aJW",
  "ss2OFGFA",
  "8LXnWtWG",
];
