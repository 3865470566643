import { Grid } from "@mui/material";
import { HomeButton } from "../common/button/HomeButton";

export const NotFound = () => {
  return (
    <>
      <div>無効なURLです。 </div>
      <Grid component="div" sx={{ my: 1 }}>
        <HomeButton />
      </Grid>
    </>
  );
};
